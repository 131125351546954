<template>
  <v-container v-if="vueTipo">
    <v-row v-if="!loading">
      <v-col cols="12">
        <PageToolbar :title="eventoTipo.descricao" icon="book" voltar dark />
      </v-col>

      <v-col cols="12">
        <v-card class="pa-6">
          <v-tabs v-model="tab">
            <v-tab>
              Cadastro
            </v-tab>
            <v-tab>
              Tarefas
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="mt-4">
            <!-- Cadastro -->
            <v-tab-item>
              <v-row>
                <!-- Descrição -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="eventoTipo.descricao"
                    label="Descrição"
                    dense
                    filled
                    hide-details
                  ></v-text-field>
                </v-col>

                <!-- Descrição Detalhada -->
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="eventoTipo.descricao_detalhada"
                    label="Descrição Detalhada"
                    dense
                    filled
                    hide-details
                  ></v-text-field>
                </v-col>

                <!-- Classificações -->
                <v-col cols="12" md="4">
                  <v-select
                    v-model="eventoTipo.classificacao_id"
                    :items="classificacoes"
                    clearable
                    item-text="descricao"
                    item-value="id"
                    dense
                    filled
                    hide-details
                    label="Classificações"
                  ></v-select>
                </v-col>

                <!-- Prioridade -->
                <v-col cols="12" md="4">
                  <v-select
                    v-model="eventoTipo.prioridade"
                    :items="prioridades"
                    clearable
                    item-text="text"
                    item-value="value"
                    dense
                    filled
                    hide-details
                    label="Prioridade"
                  ></v-select>
                </v-col>

                <!-- Veiculo obrigatorio -->
                <v-col cols="12" md="4">
                  <v-card
                    flat
                    style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                    height="56px"
                    class="pa-3 d-flex justify-space-between align-center"
                  >
                    <v-subheader
                      style="height: auto !important"
                      class="ma-0 pa-0 body-1"
                    >
                      Veiculo obrigatorio
                    </v-subheader>

                    <v-switch
                      class="ma-0 pa-0"
                      hide-details
                      :false-value="false"
                      :true-value="true"
                      v-model="eventoTipo.exige_veiculo"
                    ></v-switch>
                  </v-card>
                </v-col>

                <!-- Estoque obrigatorio -->
                <v-col cols="12" md="4">
                  <v-card
                    flat
                    style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                    height="56px"
                    class="pa-3  d-flex justify-space-between align-center"
                    v-if="modulo_estoque"
                  >
                    <v-subheader
                      style="height: auto !important"
                      class="ma-0 pa-0 body-1"
                    >
                      Estoque obrigatorio
                    </v-subheader>

                    <v-switch
                      class="ma-0 pa-0"
                      hide-details
                      :false-value="false"
                      :true-value="true"
                      v-model="eventoTipo.exige_estoque"
                    ></v-switch>
                  </v-card>
                </v-col>

                <!-- Horarios Aprovação obrigatorio -->
                <v-col cols="12" md="4">
                  <v-card
                    flat
                    style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                    height="56px"
                    class="pa-3  d-flex justify-space-between align-center"
                    v-if="modulo_estoque"
                  >
                    <v-subheader
                      style="height: auto !important"
                      class="ma-0 pa-0 body-1"
                    >
                      Horarios Aprovação Obrigatorio
                    </v-subheader>

                    <v-switch
                      class="ma-0 pa-0"
                      hide-details
                      :false-value="false"
                      :true-value="true"
                      v-model="eventoTipo.exige_horarios_evento"
                    ></v-switch>
                  </v-card>
                </v-col>

                <!-- Operacao -->
                <v-col cols="12" md="4" v-if="eventoTipo.exige_estoque">
                  <v-card flat>
                    <v-subheader
                      style="height: auto !important"
                      class="ma-0 pa-0 pb-1 body-1"
                    >
                      Operação
                    </v-subheader>
                    <v-btn-toggle
                      style="width: calc(100% / 3);"
                      dense
                      v-model="eventoTipo.op"
                      :class="
                        $vuetify.breakpoint.mdAndDown ? 'btn-toggle-mobile' : ''
                      "
                    >
                      <v-btn small height="36" block value="E">
                        Entrada
                      </v-btn>

                      <v-btn small height="36" block value="A">
                        Ambos
                      </v-btn>

                      <v-btn small height="36" block value="S">
                        Saida
                      </v-btn>
                    </v-btn-toggle>
                  </v-card>
                </v-col>

                <v-col cols="12" md="4">
                  <v-menu
                    ref="menu"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="eventoTipo.tempo_medio"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="eventoTipo.tempo_medio"
                        label="Tempo medio de trabalho"
                        prepend-inner-icon="query_builder"
                        readonly
                        filled
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      v-model="eventoTipo.tempo_medio"
                      full-width
                      format="24hr"
                      @click:minute="$refs.menu.save(eventoTipo.tempo_medio)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-btn
                v-if="vueEditTipo"
                @click="updateeventoTipo(eventoTipo)"
                fixed
                right
                bottom
                fab
                dark
                color="buttons"
                :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
              >
                <v-icon>save</v-icon>
              </v-btn>
            </v-tab-item>

            <!-- Tarefas -->
            <v-tab-item>
              <template v-if="tarefas">
                <div class="d-flex align-center justify-end mb-4">
                  <v-btn color="toolbar" depressed dark @click="addTarefa">
                    <v-icon>add</v-icon>
                    Adicionar Tarefa
                  </v-btn>
                </div>
                <v-data-table
                  :headers="headers"
                  :items="tarefas"
                  :items-per-page="10"
                  :sort-by="['id']"
                  class="data-tables data-tables__row-click"
                  @click:row="openTarefa"
                >
                </v-data-table>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <Carregando v-else />

    <v-dialog v-model="dialogTarefa" width="100%" max-width="400px">
      <v-card>
        <v-card-title class="subtitle-1 lightgray">
          <template v-if="dialogType === 'add'">Adicionar Tarefa</template>
          <template v-if="dialogType === 'open'">
            {{ tarefa.descricao }}
          </template>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogTarefa = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <!-- Descrição -->
          <v-text-field
            v-model="tarefa.descricao"
            label="Descrição"
            class="mt-4"
            filled
            hide-details
            dense
          ></v-text-field>

          <!-- Descrição Detalhada -->
          <v-text-field
            v-model="tarefa.descricao_detalhada"
            label="Descrição Detalhada"
            class="mt-4"
            filled
            hide-details
            dense
          ></v-text-field>

          <!-- Tipo -->
          <v-select
            v-model="tarefa.tipo"
            :items="tiposTarefa"
            class="mt-4"
            clearable
            item-text="text"
            item-value="value"
            dense
            filled
            hide-details
            label="Tipo"
          ></v-select>

          <!-- Obrigatorio -->
          <v-card
            flat
            style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
            height="56px"
            class="pa-3 mt-4 d-flex justify-space-between align-center"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 body-1"
            >
              Obrigatorio
            </v-subheader>

            <v-switch
              class="ma-0 pa-0"
              hide-details
              :false-value="false"
              :true-value="true"
              v-model="tarefa.obrigatorio"
            ></v-switch>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="toolbar"
            v-if="dialogType === 'add'"
            dark
            depressed
            small
            :disabled="disableTarefa"
            @click="createTarefa"
          >
            Criar
          </v-btn>
          <v-btn
            color="toolbar"
            v-if="dialogType === 'open'"
            dark
            depressed
            small
            :disabled="disableTarefa"
            @click="updateTarefa"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { getClassificacoes } from "@/services/api/classificacoes.api.js";
import {
  getEventoTipo,
  putEventoTipo,
} from "@/services/api/eventos-tipos.api.js";
import {
  getAllEventosTarefas,
  postEventosTarefas,
  putEventosTarefas,
} from "@/services/api/eventos-tarefas.api.js";
import { mapGetters, mapState } from "vuex";

export default {
  name: "EventoTipo",
  props: ["eventoTipoId"],
  data() {
    return {
      prioridades: [
        {
          text: "Normal",
          value: 0,
        },
        {
          text: "Urgente",
          value: 1,
        },
        {
          text: "Emergencial",
          value: 2,
        },
      ],
      eventoTipo: {},
      classificacoes: [],
      tarefas: null,
      tarefa: {},
      tiposTarefa: [
        {
          text: "Text",
          value: "text",
        },
        {
          text: "Checklist",
          value: "checklist",
        },
        {
          text: "Boolean",
          value: "boolean",
        },
        {
          text: "Imagem",
          value: "imagem",
        },
        {
          text: "Provisionamento de Onu",
          value: "provisionamento",
        },
      ],
      loading: false,
      loadingTarefa: false,
      tab: null,
      dialogTarefa: false,
      dialogType: "",
      headers: [
        {
          text: "Descricao",
          value: "descricao",
        },
        {
          text: "Tipo",
          value: "tipo",
        },
        {
          text: "Obrigatorio",
          value: "obrigatorio",
        },
      ],
      menu2: false,
    };
  },
  computed: {
    ...mapState("Tocsconfig", {
      modulo_estoque: (state) => state.modulo_estoque,
    }),
    disableTarefa() {
      let valid = true;
      if (
        this.tarefa.descricao &&
        this.tarefa.descricao_detalhada &&
        this.tarefa.tipo
      ) {
        valid = false;
      }
      return valid;
    },
    ...mapGetters("Componentes", ["getAccess"]),
    vueTipo() {
      return this.getAccess("vueTipo", "visualizar");
    },
    vueEditTipo() {
      return this.getAccess("vueTipo", "editar");
    },
  },
  methods: {
    getClassificacoes() {
      this.loading = true;
      getClassificacoes()
        .then((response) => {
          this.classificacoes = response;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    M_getEventoTipo() {
      this.$Progress.start();
      this.loading = true;
      getEventoTipo(this.eventoTipoId)
        .then((response) => {
          this.eventoTipo = response;
          if (
            !this.$store.state.dynamic_crumb ||
            this.$store.state.dynamic_crumb != this.eventoTipo.descricao
          ) {
            this.$store.commit(
              "UPDATE_DYNAMICCRUMB",
              this.eventoTipo.descricao
            );
          }
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getEventoTarefas() {
      getAllEventosTarefas(`?tipo_evento_id=${this.eventoTipoId}`)
        .then((response) => {
          this.tarefas = response;
        })
        .catch((error) => console.log(error));
    },
    addTarefa() {
      this.dialogType = "add";
      this.dialogTarefa = true;
    },
    openTarefa(item) {
      this.tarefa = item;
      this.dialogType = "open";
      this.dialogTarefa = true;
    },
    createTarefa() {
      this.tarefa.tipo_evento_id = this.eventoTipoId;
      this.$Progress.start();
      this.loadingTarefa = true;
      postEventosTarefas(this.tarefa)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Tarefa criada com sucesso!");
            this.tarefa = {};
            this.dialogTarefa = false;
            this.getEventoTarefas();
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => (this.loadingTarefa = false));
    },
    updateTarefa() {
      this.$Progress.start();
      this.loadingTarefa = true;
      putEventosTarefas(this.tarefa.id, this.tarefa)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Tarefa atualizada com sucesso!");
            this.dialogTarefa = false;
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => (this.loadingTarefa = false));
    },
    updateeventoTipo(eventoTipo) {
      this.atualizando = true;
      this.$Progress.start();
      putEventoTipo(eventoTipo.id, eventoTipo)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("eventoTipo atualizada com sucesso!");
            this.dialog = false;
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.atualizando = false;
        });
    },
  },
  created() {
    if (this.vueTipo) {
      this.M_getEventoTipo();
      this.getClassificacoes();
      this.getEventoTarefas();
    }
  },
  mounted() {},
};
</script>

<style lang="scss" scoped></style>
